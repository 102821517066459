/*global $*/
/*eslint no-undef: "error"*/

export default class TableButton {
    classBlocks = 'table--blocks'

    classButton = 'table--button'
    classButtonActive = 'table--button-active'
    classButtonGray = 'button-link-gray'

    classBlock = 'table--block'
    classBlockOpen = 'table-blocks__block-open'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            let block = $(this).data('block')

            $(this).closest(`.${self.classBlocks}`).find(`.${self.classButtonActive}`).removeClass(self.classButtonActive)
            $(this).addClass(self.classButtonActive)

            $(this).closest(`.${self.classBlocks}`).find(`.${self.classButton}`).addClass(self.classButtonGray)
            $(this).removeClass(self.classButtonGray)

            $(this).closest(`.${self.classBlocks}`).find(`.${self.classBlock}`).removeClass(self.classBlockOpen)
            $(this).closest(`.${self.classBlocks}`).find(`.${self.classBlock}[data-block="${block}"]`).addClass(self.classBlockOpen)

            return false
        })
    }
}
